<template>
   <div>
      <Header />
      <Posiciones />
      <Footer />
   </div>
</template>

<script>
import Posiciones from "../components/Posiciones/Posiciones.vue";
export default {
   components: {
      Posiciones,
   },
};
</script>

<style scoped></style>
