<template>
   <div class="ma-6">
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Dialog message="¿Desea guardar los scores?" @result_ok="save_scores()" ref="savescores" />
      <v-data-table :loading="isloading" :headers="headers" :items="items" :items-per-page="10" class="elevation-1 mb-8" :search="search">
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn @click="toExcel()"><v-icon left>mdi-microsoft-excel</v-icon>Exportar</v-btn>
               <v-btn @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
               <v-text-field v-model="search" label="Search" clearable hide-details></v-text-field>
            </v-toolbar>
            <v-toolbar flat color="primary">
               <v-select
                  class="my-select ma-2"
                  outlined
                  hide-details=""
                  dense
                  v-model="rango"
                  label="Rango"
                  :items="rangos"
                  item-text="text"
                  item-value="value"
               ></v-select>
               <v-select
                  class="my-select ma-2"
                  outlined
                  hide-details=""
                  dense
                  v-model="categoria"
                  label="Categoria"
                  :items="categorias"
                  item-text="text"
                  item-value="value"
               ></v-select>
               <v-select
                  class="my-select ma-2"
                  outlined
                  hide-details=""
                  dense
                  v-model="wod"
                  label="WOD"
                  :items="wods"
                  return-object
                  item-text="text"
                  item-value="value"
               ></v-select>
               <v-spacer></v-spacer>
               <v-btn @click="$refs.savescores.Show()"><v-icon left small>mdi-floppy</v-icon>Guardar</v-btn>
            </v-toolbar>
         </template>
         <template v-slot:[`item.value`]="{ item }">
            <div v-if="item.tipo == 'T'">
               <v-icon small color="warning">mdi-clock-outline</v-icon>
               {{ secondsToMinutes(item.value) }}
            </div>
            <div v-else>
               <v-icon small color="success">mdi-repeat-variant</v-icon>
               {{ item.value }}
            </div>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import fileDownload from "js-file-download";

function str_pad_left(string, pad, length) {
   return (new Array(length + 1).join(pad) + string).slice(-length);
}
function convertToCSV(arr) {
   const array = [Object.keys(arr[0])].concat(arr);
   return array
      .map((it) => {
         return Object.values(it)
            .map((el) => {
               return el.toString().split(",").join("");
            })
            .toString();
      })
      .join("\n");
}

export default {
   data() {
      return {
         search: "",
         isloading: true,
         headers: [
            { text: "ID", value: "puntuacion_id" },
            { text: "Posicion", value: "posicion" },
            // { text: "Score", value: "score" },
            { text: "Atleta", value: "atleta" },
            { text: "Gym", value: "gym" },
            { text: "Value", value: "value" },
         ],
         items: [],
         wod: null,
         categoria: null,
         rango: null,
         wods: [],
         categorias: [
            {
               value: "F",
               text: "Femenino",
            },
            {
               value: "V",
               text: "Varonil",
            },
         ],
         rangos: [
            {
               value: "P",
               text: "Principiante",
            },
            {
               value: "I",
               text: "Intermedio",
            },
            {
               value: "M",
               text: "Master",
            },
            {
               value: "A",
               text: "Avanzado",
            },
            {
               value: "R",
               text: "RX",
            },
            {
               value: "K",
               text: "Kids",
            },
         ],
      };
   },
   watch: {
      wod: function () {
         this.initialize();
      },
      categoria: function () {
         this.initialize();
      },
      rango: function () {
         this.initialize();
      },
      items: function () {
         this.items.forEach(e => e.posicion = e.score)
         // let pos = 1;
         // let skip = 1;
         // this.items[0].posicion = 1;
         // let scorePre = this.items[0]?.score;
         // for (let i = 1; i < this.items.length; i++) {
         //    if (this.items[i].score !== scorePre) {
         //       pos = pos + skip;
         //       scorePre = this.items[i].score;
         //       this.items[i].posicion = pos;
         //       skip = 1;
         //       continue;
         //    }
         //    this.items[i].posicion = pos;
         //    skip++;
         // }
      },
   },
   methods: {
      async initialize() {
         try {
            this.isloading = true;
            await this.get_wods();

            if (this.wod && this.categoria && this.rango) {
               await this.$store.dispatch("getPuntuacionesScore", {
                  desc: this.wod.tipo === 'R' ? 'desc' : 'asc'
               });
   
               let points = this.$store.state.puntuaciones_score;
               let tipo = this.wod.tipo;
               points = points.filter((elem) => elem.wod_id == this.wod.value && elem.categoria == this.categoria && elem.rango == this.rango);
               this.items = points;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      get_wods: async function () {
         try {
            this.isloading = true;
            await this.$store.dispatch("getWods");
            this.wods = [];
            this.$store.state.wods.forEach((element) => {
               this.wods.push({
                  value: element.wod_id,
                  text: element.nombre,
                  tipo: element.tipo,
               });
            });
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      save_scores: async function () {
         try {
            if (this.items && this.items.length > 0) {
               this.isloading = true;
               let scores = [];
               this.items.forEach((element) => {
                  scores.push({
                     puntuacion_id: element.puntuacion_id,
                     score: element.score,
                     pos: element.posicion,
                  });
               });
               await this.$store.dispatch("updateScore", scores);
               this.$refs.ok_noti.Show();
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      secondsToMinutes(time) {
         let minutes = Math.floor(time / 60);
         let seconds = time - minutes * 60;
         return str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
      },
      toExcel() {
         if (this.items && this.items.length > 0) {
            let csv = convertToCSV(this.items);
            fileDownload(csv, `posiciones ${new Date()}.csv`);
         }
      },
   },
   created() {
      this.initialize();
   },
};
</script>

<style scoped>
.my-select {
   max-width: 200px;
}
</style>
